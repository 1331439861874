import React from "react"
import * as kalStyle from "../styles/kalkulatorbmi.module.css"

export default class KalkulatorBeratIdeal extends React.Component {


  state = {
    tinggi: "",
    formula: "Semua formula",
    gender: "true",
    hasil: '',
    options: [
      "Semua formula",
      'BMI range formula',
      "Hamwi formula",
      'Devine formula',
      "Miller formula",
      "Robinson formula",
      "Lemmens formula",
      "Broca formula",
    ]
  }

  // BMI range formula
  bmi(o){
    var beratMinimal = Math.pow( (o.tinggi/100), 2 ) * 18.5,
    beratMaksimal = Math.pow( (o.tinggi/100), 2 ) * 24.999999;
    return beratMinimal.toFixed(2) + '</strong> Kg sampai <strong>' + beratMaksimal.toFixed(2);
  }

  // Broca formula
  broca(o){
    var normal = (parseInt(o.tinggi) - 100);
    return (normal - (normal * (o.gender ? 0.1 : 0.15))).toFixed(2);
  }

  // Devine formula
  devine(o){
    var inch = o.tinggi / 2.54,
    baseWeight = o.gender ? 50 : 45.5,
    baseFeet = 12 * 5,
    inchOver5Feet = (inch > baseFeet ? inch - baseFeet : 0);
    return (baseWeight + (inchOver5Feet * 2.3)).toFixed(2);
  }

  // Robinson formula
  robinson(o){
    var inch = o.tinggi / 2.54,
    baseWeight = o.gender ? 52 : 49,
    baseFeet = 12 * 5,
    inchOver5Feet = (inch > baseFeet ? inch - baseFeet : 0);
    return (baseWeight + (inchOver5Feet * (o.gender ? 1.9 : 1.7))).toFixed(2);
  }

  // Miller formula
  miller(o){
    var inch = o.tinggi / 2.54,
    baseWeight = o.gender ? 56.2 : 53.1,
    baseFeet = 12 * 5,
    inchOver5Feet = (inch > baseFeet ? inch - baseFeet : 0);
    return (baseWeight + (inchOver5Feet * (o.gender ? 1.41 : 1.36))).toFixed(2);
  }

  // Hamwi formula
  hamwi(o){
    var inch = o.tinggi / 2.54,
    baseWeight = o.gender ? 106 : 100,
    baseFeet = 12 * 5,
    inchOver5Feet = (inch > baseFeet ? inch - baseFeet : 0),
    lb = baseWeight + (inchOver5Feet * (o.gender ? 6 : 5));
    return lb*0.45359233;

  }

  // Lemmens formula
  lemmens(o){
    return (22 * Math.pow( (o.tinggi/100), 2 )).toFixed(2);
  }

  handleInputChange = (event) => {

    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })

    let tinggi = name === 'tinggi' ? value : this.state.tinggi
    let gender = name === 'gender' ? value : this.state.gender
    let formula = name === 'formula' ? value : this.state.formula
    let hasil = '',
    hasilEach = ''


    if(tinggi > 50){
      let isBMI = formula === 'BMI range formula' ? true : false
      let isHamwi = formula === 'Hamwi formula' ? true : false
      let obj = {tinggi,gender}

      // Cek formula yang dipilih
      switch (formula) {
        case 'BMI range formula':
          hasil = this.bmi(obj);
          break;
          case 'Devine formula':
            hasil = this.devine(obj);
            break;
            case 'Robinson formula':
              hasil = this.robinson(obj);
              break;
              case 'Miller formula':
                hasil = this.miller(obj);
                break;
                case 'Hamwi formula':
                  hasil = this.hamwi(obj);
                  break;
                  case 'Lemmens formula':
                    hasil = this.lemmens(obj);
                    break;
                    case 'Broca formula':
                      hasil = this.broca(obj);
                      break;
                      case 'Semua formula':
                        hasil = 'Semua formula';
                        break;
        default:
        hasil = false;
      }

      if(hasil){
        // Jika tampilkan semua formula
        if(hasil === 'Semua formula'){
          var htmlToAppend = '';
          this.state.options.map((o) => {
            let isBMIEach = o === 'BMI range formula' ? true : false
            let isHamwiEach = o === 'Hamwi formula' ? true : false

            if(o !== 'Semua formula'){
              switch (o) {
                case 'BMI range formula':
                  hasilEach = this.bmi(obj);
                  break;
                  case 'Devine formula':
                    hasilEach = this.devine(obj);
                    break;
                    case 'Robinson formula':
                      hasilEach = this.robinson(obj);
                      break;
                      case 'Miller formula':
                        hasilEach = this.miller(obj);
                        break;
                        case 'Hamwi formula':
                          hasilEach = this.hamwi(obj);
                          break;
                          case 'Lemmens formula':
                            hasilEach = this.lemmens(obj);
                            break;

                default:
                hasilEach = this.broca(obj);
              }

              htmlToAppend += `
              <div class="${kalStyle.bmibg } ${kalStyle.bginfo} ${kalStyle.textwhite}"><p>Berdasarkan ${o}</p><p>
              Berat idealnya kamu adalah ${isBMIEach ? ':<br>antara ' : ''}<strong>${isHamwiEach ? hasilEach.toFixed(2).toString() : hasilEach.toString() }</strong> Kg</p>
              ${
                isHamwiEach ? `<hr><p>Jika lingkar pergelangan tanganmu dibawah 17,78 Cm maka berat idealmu adalah <strong>
                ${ (hasilEach - (hasilEach * 0.1)).toFixed(2).toString() }</strong> Kg dan jika diatas 17,78 Cm maka berat idealmu adalah <strong>
                ${ (hasilEach + (hasilEach * 0.1)).toFixed(2).toString() }</strong> Kg</p>` :``
              }
              </div>`
            }
            return null
          })

          // Tampilkan semua hasil formula
          // $('#hasil').html(htmlToAppend).fadeIn();
          this.setState({
            hasil:htmlToAppend
          });

        }else{
          // Tampilkan hasil salah satu formula saja
          this.setState({
            hasil: `
            <div class="${kalStyle.bmibg } ${kalStyle.bginfo} ${kalStyle.textwhite}"><p>Berdasarkan ${formula}</p><p>
            Berat idealnya kamu adalah ${isBMI ? ':<br>antara ' : ''}<strong>${isHamwi ? hasil.toFixed(2).toString() : hasil.toString() }</strong> Kg</p>
            ${
              isHamwi ? `<hr><p>Jika lingkar pergelangan tanganmu dibawah 17,78 Cm maka berat idealmu adalah <strong>
              ${ (hasil - (hasil * 0.1)).toFixed(2).toString() }</strong> Kg dan jika diatas 17,78 Cm maka berat idealmu adalah <strong>
              ${ (hasil + (hasil * 0.1)).toFixed(2).toString() }</strong> Kg</p>` :``
            }
            </div>`
          })
        }
      }


    }else{
      this.setState({hasil:''})
    }


  }
  render() {
    return (
      <form className={kalStyle.form}>
        <h6 style={{marginTop:'0.3rem',marginBottom:0,fontWeight:600,opacity: 0.6}}>Kalkulator berat badan ideal</h6>
        <small style={{opacity: 0.4}}>*Untuk 20 tahun keatas</small>
        <hr style={{background: '#242121',boxShadow: '1px 1px 0 rgba(255,255,255,0.23)',height: 2}}/>

        <div className="form-group">
          <label htmlFor="none" style={{fontSize:'0.8rem',fontWeight:500,opacity: 0.7}}>Jenis kelamin kamu:</label>
          <div id="gender" className={`${kalStyle.inputgroup}`}>
            <div className={`${kalStyle.formcheckinline}`}>
              <input className={`${kalStyle.formcheckinput}`} type="radio" name="gender" id="gender1" value="true" onChange={this.handleInputChange} checked={this.state.gender === "true"}/>
              <label htmlFor="gender1">Lelaki</label>
            </div>
            <div className="form-check form-check-inline">
              <input className={`${kalStyle.formcheckinput}`} type="radio" name="gender" id="gender2" value="" onChange={this.handleInputChange} checked={this.state.gender === ""}/>
              <label htmlFor="gender2">Perempuan</label>
            </div>
          </div>
        </div>
        <br/>
        <div>
          <label htmlFor="formula" style={{fontSize:'0.8rem',fontWeight:500,opacity: 0.7}}>Pilih formula:</label>
          <div className={`${kalStyle.inputgroup}`}>
            <select name="formula" className={`${kalStyle.formcontrol}`} value={this.state.formula} onBlur={()=>{}} onChange={(e) => this.handleInputChange(e)}>
              {
                this.state.options.map((o) => {
                  return (
                    <option key={o} value={o}>{o}</option>
                  )
                })
              }
            </select>
          </div>
        </div>
        <br/>
        <div>
          <label htmlFor="tinggi" style={{fontSize:'0.8rem',fontWeight:500,opacity: 0.7}}>Tinggi badan kamu:</label>
          <div className={kalStyle.inputgroup}>
            <input
              type="number"
              name="tinggi"
              value={this.state.tinggi}
              onChange={this.handleInputChange}
              className={kalStyle.formcontrol}
            />
            <div className={kalStyle.inputgroupappend}>
              <span className={kalStyle.inputgrouptext}>Cm</span>
            </div>
          </div>
        </div>

        <div
          dangerouslySetInnerHTML={{ __html: this.state.hasil }}
        />
      </form>
    )
  }
}
