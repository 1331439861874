import React from "react"
import * as kalStyle from "../styles/kalkulatorbmi.module.css"

export default class KalkulatorBMI extends React.Component {


  state = {
    tinggi: "",
    berat: "",
    hasil: '',
    textBg: '',
    textColor: ''
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })

    this.textBg = kalStyle.bgdanger2
    this.textColor = kalStyle.textwhite
    let tinggi = name === 'tinggi' ? value : this.state.tinggi
    let berat = name === 'berat' ? value : this.state.berat


    if(tinggi > 50 && berat > 30){
      tinggi = tinggi / 100
      let bmi = (berat / Math.pow( tinggi, 2 )).toFixed(1)
      let hasil = `Nilai BMI/IMT kamu: <strong>${bmi}</strong><hr>`
      let textBg = kalStyle.bgwarning2
      let textColor = kalStyle.textwhite

      if(bmi < 18.5){
        hasil += 'Berat badan kamu <strong>Kurang</strong>';
      }
      if(bmi >= 18.5 && bmi < 25){
        hasil += 'Berat badan kamu <strong>Normal</strong>';
        textBg = kalStyle.bgsuccess
      }
      if(bmi >= 25 && bmi < 30){
        hasil += 'Berat badan kamu <strong>Berlebih</strong>';
        textBg = kalStyle.bgwarning
        // textColor = kalStyle.textdark
      }
      if(bmi >= 30 && bmi < 35){
        hasil += 'Kamu <strong>Obesitas</strong> tingkat 1';
        textBg = kalStyle.bgwarning2
      }
      if(bmi >= 35 && bmi < 40){
        hasil += 'Kamu <strong>Obesitas</strong> tingkat 2';
        textBg = kalStyle.bgdanger
      }
      if(bmi >= 40){
        hasil += 'Kamu <strong>Obesitas</strong> tingkat 3';
        textBg = kalStyle.bgdanger2
      }

      this.setState({hasil,textBg,textColor})

    }else{
      this.setState({hasil:''})
    }
  }

  render() {
    return (
      <form className={kalStyle.form}>
        <h6 style={{marginTop:'0.3rem',marginBottom:0,fontWeight:600,opacity: 0.6}}>Kalkulator Body Mass Index</h6>
        <small style={{opacity: 0.5}}>*Untuk 20 tahun keatas</small>
        <hr style={{background: '#242121',boxShadow: '1px 1px 0 rgba(255,255,255,0.23)',height: 2}}/>
        <div style={{display: 'table-cell',paddingRight:10}}>
          <label htmlFor="tinggi" style={{fontSize:'0.8rem',fontWeight:500,opacity: 0.7}}>Tinggi badan kamu:</label>
          <div className={kalStyle.inputgroup}>
            <input
              type="number"
              name="tinggi"
              value={this.state.tinggi}
              onChange={this.handleInputChange}
              className={kalStyle.formcontrol}
            />
            <div className={kalStyle.inputgroupappend}>
              <span className={kalStyle.inputgrouptext}>Cm</span>
            </div>
          </div>
        </div>
        <div style={{display: 'table-cell',paddingLeft:10}}>
          <label htmlFor="berat" style={{fontSize:'0.8rem',fontWeight:500,opacity: 0.7}}>Berat badan kamu:</label>
          <div className={kalStyle.inputgroup}>
            <input
              type="number"
              name="berat"
              value={this.state.berat}
              onChange={this.handleInputChange}
              className={kalStyle.formcontrol}
            />
            <div className={kalStyle.inputgroupappend}>
              <span className={kalStyle.inputgrouptext}>Kg</span>
            </div>
          </div>
        </div>
        <div
          className={this.state.hasil ? `${kalStyle.bmibg } ${this.state.textBg} ${this.state.textColor}` : ''}
          dangerouslySetInnerHTML={{ __html: this.state.hasil }}
        />
      </form>
    )
  }
}
