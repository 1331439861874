// extracted by mini-css-extract-plugin
export var bgdanger = "kalkulatorbmi-module--bgdanger--MJRxo";
export var bgdanger2 = "kalkulatorbmi-module--bgdanger2--pJVTw";
export var bginfo = "kalkulatorbmi-module--bginfo--JPuW+";
export var bgsuccess = "kalkulatorbmi-module--bgsuccess--HSvye";
export var bgwarning = "kalkulatorbmi-module--bgwarning--HWcze";
export var bgwarning2 = "kalkulatorbmi-module--bgwarning2--odnp-";
export var bmibg = "kalkulatorbmi-module--bmibg--aCOZt";
export var form = "kalkulatorbmi-module--form--7JNcu";
export var formcheckinline = "kalkulatorbmi-module--formcheckinline--O1CDn";
export var formcheckinput = "kalkulatorbmi-module--formcheckinput--Jfr-Q";
export var formcontrol = "kalkulatorbmi-module--formcontrol--LYTQL";
export var inputgroup = "kalkulatorbmi-module--inputgroup--xHzWv";
export var inputgroupappend = "kalkulatorbmi-module--inputgroupappend--AfIWk";
export var inputgrouptext = "kalkulatorbmi-module--inputgrouptext--H1T-2";
export var textdark = "kalkulatorbmi-module--textdark--akJBW";
export var textwhite = "kalkulatorbmi-module--textwhite--clgDb";